$first-color: #4e4376;
$second-color: #2b5876;

////////////////////////////////////////////////
// YETI
////////////////////////////////////////////////
$grey-darker: #222;
$grey-dark: #333;
$grey: #888;
$grey-light: #ccc;
$grey-lighter: #e9e9e9;

$orange: #E99002;
$green: #43ac6a;
$cyan: #5bc0de;
$red: #F04124;

$primary: #008cba !default;
$warning: $orange;

$orange-invert: #fff;
$warning-invert: $orange-invert;

$border: #e9e9e9;
$family-sans-serif: "Open Sans",
"Helvetica Neue",
Arial,
sans-serif;
$body-size: 15px;

$radius: 0;
$radius-small: 0;
$radius-large: 0;

$subtitle-color: $grey;

$button-background-color: $grey-lighter;
$button-border-color: darken($button-background-color, 5);

$navbar-background-color: #767676;
$navbar-item-color: rgba(#fff, 0.6);
$navbar-item-hover-color: #fff;
$navbar-item-active-color: #fff;
$navbar-item-hover-background-color: rgba(#000, 0.1);
$navbar-dropdown-arrow: $navbar-item-color;

$bulmaswatch-import-font: true !default;

$box-shadow: 0 0 0 1px $border;
$card-shadow: 0 0 0 1px $border;
