// Overrides
@if $bulmaswatch-import-font {
	@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,300,700&display=swap);
}

.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea {
	height: 2.534em;
}

.button {
	&.is-active,
	&:active {
		box-shadow: inset 1px 1px 4px rgba($grey-darker, 0.3);
	}

	@each $name, $pair in $colors {
		$color: nth($pair, 1);
		$color-invert: nth($pair, 2);

		&.is-#{$name} {
			border-color: darken($color, 5);

			&.is-hovered,
			&:hover {
				background-color: darken($color, 10);
			}

			&.is-active,
			&:active {
				box-shadow: inset 1px 0 3px rgba($grey-darker, 0.3);
				background-color: darken($color, 10);
			}
		}
	}

	&.is-loading:after {
		border-color: transparent transparent $grey-light $grey-light;
	}
}

.input,
.textarea {
	box-shadow: none;
}

.box,
.card {
	box-shadow: 0 0 0 1px $border;
}

.notification {
	@each $name, $pair in $colors {
		$color: nth($pair, 1);
		$color-invert: nth($pair, 2);

		&.is-#{$name} {
			a:not(.button) {
				color: $color-invert;
				text-decoration: underline;
			}
		}
	}
}

.navbar {
	&.is-transparent {
		background-color: transparent;

		.navbar-item,
		.navbar-link {
			color: $link;

			&:after {
				border-color: currentColor;
			}
		}

		.navbar-item::first-letter {
			text-transform: uppercase;
		}
	}

	@include desktop {
		.has-dropdown .navbar-item {
			color: $text;
		}
	}

	@include touch {
		.navbar-menu {
			background-color: inherit;
		}

		@each $name, $pair in $colors {
			$color: nth($pair, 1);
			$color-invert: nth($pair, 2);

			&.is-#{$name} {
				.navbar-item,
				.navbar-link {
					color: $color-invert;
				}
			}
		}
	}
}

.hero {
	.navbar {
		.navbar-item,
		.navbar-link {
			color: $link;

			&:after {
				border-color: currentColor;
			}
		}

		@include desktop {
			.has-dropdown .navbar-item {
				color: $text;
			}
		}
	}

	@each $name, $pair in $colors {
		$color: nth($pair, 1);
		$color-invert: nth($pair, 2);

		&.is-#{$name} {
			.navbar-item,
			.navbar-link {
				color: $color-invert;
			}
		}
	}
}

.progress,
.tag {
	border-radius: $radius;
}

a {
	text-decoration: none;
}
