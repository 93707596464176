/**** Sass Variables ****/
$bodyFont: 'Open Sans', sans-serif;

.card-work {
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
	cursor: pointer;
	transition: 0.5s;

	.card-work-image {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 2;
		background-color: #000;
		transition: 0.5s;
	}

	&:hover img {
		opacity: 0.4;
		transition: 0.5s;
	}
}

.card-work:hover .card-work-image {
	transform: translateY(-100px);
	transition: all 0.9s;
}

/**** Social Icons *****/

.social-icons {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	display: flex;

	li {
		list-style: none;

		span {
			position: relative;
			display: block;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			background: #fff;
			font-size: 23px;
			color: #333;
			font-weight: bold;
			margin: 0 6px;
			transition: 0.4s;
			transform: translateY(140px);
			opacity: 0;
		}
	}
}

.card-work:hover .social-icons li span {
	transform: translateY(0px);
	opacity: 1;
}

.social-icons li span:hover {
	background: #000;
	transition: 0.2s;

	.fab {
		color: #fff;
	}
}

.social-icons li span .fab {
	transition: 0.8s;

	&:hover {
		// transform: rotateY(360deg);
		color: #fff;
	}
}

.card-work:hover li:nth-child(1) span {
	transition-delay: 0.1s;
}

.card-work:hover li:nth-child(2) span {
	transition-delay: 0.2s;
}

.card-work:hover li:nth-child(3) span {
	transition-delay: 0.3s;
}

.card-work:hover li:nth-child(4) span {
	transition-delay: 0.4s;
}

.card-work:hover li:nth-child(5) span {
	transition-delay: 0.5s;
}

/**** Personal Details ****/

.details {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #fff;
	width: 100%;
	height: 120px;
	z-index: 1;
	padding: 10px;

	h2 {
		margin: 30px 0;
		padding: 0;
		text-align: center;

		.job-title {
			font-size: 1rem;
			line-height: 2.5rem;
			color: #333;
			font-weight: 300;
		}
	}
}

.jane {
	position: absolute;
	bottom: -120px;
	left: 0;
	opacity: 0;
	background: #fff;
	width: 100%;
	z-index: 3;
	padding-top: 10px;
	transition: 0.4s;
	text-align: center;
}

.jane-footer-item {
	padding-bottom: 15px;
}

.profile-two .social-icons li span {
	border-radius: 50%;
}

.card-work:hover .profile-img--two {
	transform: rotateY(180deg);
}

.card-work:hover .jane {
	bottom: 0;
	left: 0;
	transition-delay: 0.5s;
	opacity: 1;
}

.article {
	padding: 3rem 5rem 1rem 5rem;
}
