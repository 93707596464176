.diagonal-hero-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 400px;
	background: #2b5876;
	/* fallback for old browsers */
	// background: -webkit-linear-gradient(280deg, #4e4376, #2b5876);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		280deg,
		#4e4376,
		#43337c,
		#362670,
		#2c1e5e,
		#113a55,
		#174563,
		#1d608d,
		#2b5876
	);
	// background: linear-gradient(280deg, #4e4376, #2b5876);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	z-index: -1;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);

	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.hero-body {
	content-visibility: auto;

	img {
		position: absolute;
		bottom: 0;
		right: -430px;
		animation: linear alternate;
		animation-name: run;
		animation-delay: 3s;
		animation-duration: 2s;
		animation-fill-mode: forwards;

		max-width: 48%;
		z-index: -10;
		filter: blur(0.3px) brightness(1.1) contrast(1.1) saturate(1.2);
	}
}

@keyframes run {
	0% {
		right: -430px;
		opacity: 0.2;
		// transform: translateX(0);
	}

	50% {
		right: -100px;
		opacity: 0.3;
	}

	100% {
		right: 0;
		opacity: 1;
		// transform: translateX(-100%);
	}
}

// body {
//   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//   background-size: 400% 400%;
//   animation: gradient 15s ease infinite;
// }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}
