body {
	min-width: fit-content;
	overflow: hidden;
}

.grid {
	display: grid;
	grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 724px) [md-end] 1.5rem 1fr [xl-end];
}

.grid * {
	grid-column: md;
}

.grid-xl {
	grid-column: xl;
}

.is-paddingless-horizontal {
	padding-left: 0;
	padding-right: 0;
}

.center,
.center-column,
.top,
.right,
.bottom,
.left {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex {
	display: flex;
	flex-direction: row;
}

.flex-space-between {
	align-content: space-between;
	align-items: center;
	justify-content: space-between;
}

.center-column {
	flex-direction: column;
}

.top {
	align-items: flex-start;
}

.right {
	justify-content: flex-end;
}

.bottom {
	align-items: flex-end;
}

.left {
	justify-content: flex-start;
}

.single-spaced,
.single-spaced * {
	line-height: 1;
}

.about-me {
	max-height: 500px;
}

.blink::after {
	content: '|';
	opacity: 1;
	font-size: 1.7rem;
	animation: blink 0.8s infinite;
}

.ck.ck-content {
	min-height: 300px;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding {
	padding: 0;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.margin-bottom {
	margin-bottom: 30px !important;
}

.margin-right {
	margin-right: 25px;
}

.hidden {
	visibility: hidden;
}

.is-transparent {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
}

.breadcrumb {
	white-space: break-spaces;
}

.truncate {
	width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dont-break-out {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.social-media {
	flex-wrap: wrap;
}

.hero-portfolios {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
}

.tabs {
	ul {
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		li {
			text-transform: uppercase;
		}
	}
}

@media (max-width: 1024px) {
	.desktop {
		display: none;
	}
}

@media (max-width: 672px) {
	:root {
		font-size: 90%;
	}
}

// mobile
@media (max-width: 414px) {
	.article {
		padding: 3rem 2rem 1rem 2rem;
	}
}

// tablet
@media only screen and (min-width: 769px) and (max-width: 1407px) {
	.social-media {
		padding: 18px 0px;

		// a {
		//   padding-left: 7%;
		//   padding-right: 7%;
		// }
	}
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
