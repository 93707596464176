@mixin typing($width, $delay, $animation, $duration, $opacity:0) {
  width: $width;

  @if $opacity {
    opacity: 0;
  }

  animation: $animation $duration steps(40, end);
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.css-typing {

  p,
  h1,
  h2 {
    border-right: .15em solid orange;
    font-family: "Courier";
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 776px) {
    font-size: 2rem;
  }
}

.css-typing p:nth-child(1) {
  @include typing(9em, 0s, type, 2s, false)
}

.css-typing h1 {
  @media (max-width: 776px) {
    font-size: 28px !important;
  }


  @include typing(11em, 2s, type2, 2s)
}

.css-typing h2 {
  @media (max-width: 776px) {
    font-size: 14px;
  }

  @include typing(23em, 4s, type3, 2s)
}

.css-typing p:last-child() {
  width: 11.5em;
  opacity: 0;
  animation: type4 5s steps(20, end), blink .5s step-end infinite alternate;
  animation-delay: 6s;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: .15em solid orange;
  }

  100% {
    border: none;
  }
}


@keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: .15em solid orange;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: .15em solid orange;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type4 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}