/* ========================= */

.waves {
  position: absolute;
  z-index: -10;
  // bottom: 0;
  top: 0;
  // left: 0;
  right: 0;
  height: 50px;
  background-color: var(--color);
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 500ms;
}

// linear-gradient(to right, #4e4376, #2b5876);

.waves::before,
.waves::after {
  content: '';
  position: fixed;
  width: 300vw;
  height: 300vw;
  top: -65vw;

  // mobile
  @media (max-width: 614px) {
    top: -55vw;
  }


  left: 50%;
  transform: translate(-50%, -75%);
}

// mobile
@media (max-width: 414px) {}


.waves::before {
  border-radius: 44%;
  background: #0a0a0a6b;
  // background: #4e43769c;
  animation: waves 20s linear infinite;
}

.waves::after {
  border-radius: 44%;
      height: 280vw;
    width: 280vw;
  // background: rgba(51, 51, 51, 0.5);
  background: #0a0a0a;
  animation: waves 31s linear infinite;
}

@keyframes waves {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

/* ========================= */

// creates randomized star sizes
@function stars($n) {
  $value: '#{random(2000)}px #{random(2000)}px #767676';

  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #767676';
  }

  @return unquote($value);
}

// store random stars
$stars-small: stars(700);
$stars-medium: stars(200);
$stars-big: stars(100);


.stars {
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 1s ease-in-out;
}

.stars>.small {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: starsAnimation 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
  }
}

.stars>.medium {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: starsAnimation 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
  }
}

.stars>.big {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  border-radius: 100%;
  animation: starsAnimation 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    border-radius: 100%;
  }
}

// swap from/to values to reverse animation
@keyframes starsAnimation {
  from {
    transform: translateY(-2000px);
  }

  to {
    transform: translateY(0px);
  }
}






// order-radius: var(--geist-radius);
// background-image: linear-gradient(345deg, var(--accents-1), var(--accents-2), var(--accents-2), var(--accents-1));
// background-size: 400% 100%;
// -webkit-animation: skeleton_loading__sYlWE 8s ease-in-out infinite;
// animation: skeleton_loading__sYlWE 8s ease-in-out infinite;


// inline style in element
// width: 100%;
// min-height: 24px;
// margin-bottom: calc(0px);
// padding-top: calc(62.5% + 1px);
