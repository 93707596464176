.container-btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  text-align: center;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

//default button
.btn {
  color: #000;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  &.is-fullwidth {
    width: 100%;
  }
}

/////////////////////////////
//button-4 
///////////////////////////
.btn-fancy {
  border: 1px solid;
  overflow: hidden;
  position: relative;

  span {
    z-index: 20;
  }

  &:after {
    background: #000;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }

  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}


.button:focus,
.button.is-focused {
  border-color: #2c577678;
  color: #222;
}